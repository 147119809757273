var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"prose-www@19.2.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { useEffect } from 'react';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

import { initPublicEnvs } from 'utils/initPublicEnvs';
import { ignoreErrors, shouldRepportError } from 'utils/setupSentry';

if (!window?.PROSE_ENV?.REACT_APP_ENV) {
  const ENV = initPublicEnvs();
  window.PROSE_ENV = ENV;
}

/**
 * This file configures the initialization of Sentry on the browser.
 * The config added here will be used whenever a page is visited.
 * https://docs.sentry.io/platforms/javascript/guides/nextjs/
 */
if (window.PROSE_ENV.REACT_APP_ENV !== 'test') {
  Sentry.init({
    dsn: window.PROSE_ENV.REACT_APP_SENTRY,
    tracesSampleRate: window.PROSE_ENV.SENTRY_TRACES_SAMPLE_RATE,
    environment: window.PROSE_ENV.REACT_APP_ENV,
    debug: false, // Disable debug mode, activate it manually only if necessary
    release: `prose-www@${window.PROSE_ENV.REACT_APP_VERSION_WWW}`,
    ignoreErrors,
    integrations: [
      new ExtraErrorData(),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    normalizeDepth: 5,
    beforeSend(data, hint) {
      // Ignore IE browsers
      if (/(MSIE|Trident)/.test(navigator.userAgent)) {
        return null;
      }

      // returning null here avoids data to be collected by Sentry
      return shouldRepportError(data, hint) ? data : null;
    },
    beforeBreadcrumb(breadcrumb) {
      // filter out console breadcrumbs
      if (breadcrumb.category === 'console') {
        return null;
      }
      return breadcrumb;
    },
    /**
     * Some 3rd parties flood Sentry with unactionable script errors.
     * This is a workaround to ALL errors from a given one to be sent to Sentry.
     * If you'd like to only filter out a particular error, prefer either the ignoreErrors
     * field or the shouldRepportError within beforeSend.
     */
    denyUrls: [
      // Tiktok analytics
      /analytics\.tiktok\.com\/i18n\/pixel\/sdk\.js/i,
    ],
    ...(window.PROSE_ENV.REACT_APP_ENV !== 'development'
      ? {
          tracesSampleRate: Number(window.PROSE_ENV.REACT_APP_SENTRY_RATE),
          tracePropagationTargets: ['localhost', window.PROSE_ENV.REACT_APP_ROOT_URL],
        }
      : {}),
  });
}
